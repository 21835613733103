import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { styles, useBreakpoint } from "../../../helpers";
import { type keyPair } from "../../../models";
import { Select } from "../select";
import { TextBox } from "../textBox";
import { type CustomTable } from "./dataGrid";

interface DataGridPaginationProps<T> {
    table: CustomTable<T>;
    page: number;
    setPage: (page: number) => void;
    itemsPerPage: number;
    setItemsPerPage: (itemsPerPage: number) => void;
    pageSizeOptions: number[];
    totalItems: number;
}

export function DataGridPagination<T>({
    table,
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    pageSizeOptions,
    totalItems,
}: DataGridPaginationProps<T>) {
    const [pageString, setPageString] = useState<string>(page.toString());
    const { isSm } = useBreakpoint("sm");
    const pageCount = table.getPageCount();
    const pageSizes = useMemo(
        () => (pageSizeOptions ?? []).map((item) => ({ value: item, label: item })),
        [pageSizeOptions],
    );
    useEffect(() => {
        setPageString(page.toString());
    }, [page]);

    /**Sets new page, protecting against values to low or high */
    const updatePage = useCallback(
        (newPage: number) => {
            const fixedPage = Math.max(Math.min(newPage, pageCount), 1);
            setPage(fixedPage);
        },
        [pageCount, setPage],
    );

    const goToFirstPage = useCallback(() => updatePage(1), [updatePage]);
    const goToLastPage = useCallback(() => updatePage(pageCount), [updatePage, pageCount]);
    const pageBack = useCallback(() => updatePage(page - 1), [updatePage, page]);
    const pageForward = useCallback(() => updatePage(page + 1), [updatePage, page]);
    const gotoPage = useCallback(() => updatePage(parseInt(pageString)), [updatePage, pageString]);

    const selectPageSize = useCallback(
        (value: keyPair<number>) => {
            goToFirstPage();
            setItemsPerPage(parseInt(value.value?.toString() ?? "10"));
        },
        [goToFirstPage, setItemsPerPage],
    );

    if ((pageSizeOptions?.at(0) ?? 10) > totalItems) {
        if (isSm) return null;

        return (
            <div className="col-end-1 grid grid-cols-1 gap-2 rounded-t-none border-t border-gray-200 p-2 dark:text-white sm:flex sm:items-center sm:justify-end">
                <div className="h-10" />
            </div>
        );
    }

    return (
        <div className="col-end-1 grid grid-cols-1 justify-center gap-2 rounded-t-none border-t border-gray-200 p-2 dark:text-white sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex-start flex flex-col justify-center gap-2">
                <span className="flex items-center gap-1"></span>
                <div className="flex flex-wrap gap-2">
                    <PageNavButton onClick={goToFirstPage} label="First Page" disabled={page === 1}>
                        <ChevronDoubleLeftIcon className="w-6" />
                    </PageNavButton>
                    <PageNavButton onClick={pageBack} label="Previous Page" disabled={page === 1}>
                        <ChevronLeftIcon className="w-6" />
                    </PageNavButton>

                    <div className={styles("flex items-center gap-1", isSm && "sm:order-1")}>
                        <strong>Page</strong>
                        <TextBox
                            id="data-grid-goto-page"
                            type="number"
                            value={pageString}
                            changeHandler={setPageString}
                            onBlur={gotoPage}
                            className="mt-[-.5rem] w-12 arrow-hide text-center"
                        />
                        <strong>of {pageCount}</strong>
                    </div>
                    <PageNavButton onClick={pageForward} label="Next Page" disabled={page === pageCount}>
                        <ChevronRightIcon className="w-6" />
                    </PageNavButton>
                    <PageNavButton onClick={goToLastPage} label="Last Page" disabled={page === pageCount}>
                        <ChevronDoubleRightIcon className="w-6" />
                    </PageNavButton>
                </div>
            </div>
            <div className="flex items-center gap-1">
                <Select
                    id="data-grid-items-per-page"
                    className="mt-[-.5rem] text-left sm:w-20"
                    value={itemsPerPage}
                    changeHandler={selectPageSize}
                    options={pageSizes}
                />
                <strong>Items Per Page</strong>
            </div>
        </div>
    );
}

interface PageNavButtonProps {
    onClick: () => any;
    label: string;
    disabled?: boolean;
    children?: ReactNode;
}

function PageNavButton(props: PageNavButtonProps) {
    const { onClick, label, disabled, children } = props;
    return (
        <button
            className={styles("rounded border p-1", !disabled && "hover:opacity-80", disabled && "text-gray-400")}
            aria-label={label}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
